var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');


module.exports = function () {
	var _mainNavMobile = document.querySelector('.main-nav-mobile')
	,	_btBurger = _mainNavMobile.querySelector('.bt-burger')
	,	_btBurgerIcon = _btBurger.querySelector('div')
	,	_innerHeader = _mainNavMobile.querySelector('.inner-header')
	,	_nav = _mainNavMobile.querySelector('nav')
	,	_navItems = _nav.querySelectorAll('li')
	//,	_btClose = _mainNavMobile.querySelector('.bt-close')
	,	isOpened = false
	,	headerH = _innerHeader.offsetHeight
	,	winW = window.innerWidth

	addHandlers()

	Array.prototype.forEach.call(_navItems, function(el) {
		TweenMax.to(el, 0, {yPercent: -50, autoAlpha: 0})
		el.querySelector('a').addEventListener('click', function(e){
			console.log(e.target);
			e.stopPropagation()
		})
	})

	function addHandlers(){
		_btBurger.addEventListener('click', clickBurger)
		//_btClose.addEventListener('click', navToggleClass)
	}

	function clickBurger(){
		if(isOpened){
			isOpened = false;
			classList(_btBurgerIcon).add('icon-menu')
			classList(_btBurgerIcon).remove('icon-close')
			Array.prototype.forEach.call(_navItems, function(el,i) {
				TweenMax.to(el, .2, {yPercent: -50, autoAlpha: 0, delay: i*.1, ease: Circ.easeIn})
			})
			TweenMax.fromTo(_mainNavMobile, .3, {backgroundColor: 'rgba(0,0,0,.2)'}, {backgroundColor: 'rgba(0,0,0,.0)', delay: .3})
			TweenMax.fromTo(_nav, .3, {backgroundColor: 'rgba(255,255,255,1)'}, {backgroundColor: 'rgba(255,255,255,0)', delay: .3})
			TweenMax.to(_innerHeader, .3, {width: 0, ease:Circ.easeInOut, delay: .5})
			TweenMax.to(_mainNavMobile, 0, {width: winW, height:70, delay: .9, onComplete:function(){
				_mainNavMobile.style.zIndex = 1;
			}})

		} else {
			isOpened = true;
			_mainNavMobile.style.zIndex = 1000;
			classList(_btBurgerIcon).remove('icon-menu')
			classList(_btBurgerIcon).add('icon-close')
			TweenMax.to(_mainNavMobile, 0, {width: '100%', height:'100vh'})
			TweenMax.to(_innerHeader, .3, {width: winW - headerH, ease:Circ.easeInOut})
			TweenMax.fromTo(_nav, .3, {backgroundColor: 'rgba(255, 255, 255, 0.01)'},{backgroundColor: 'rgba(255, 255, 255, 1)', delay: .3})

			TweenMax.fromTo(_mainNavMobile, .3, {backgroundColor: 'rgba(0,0,0,0)'}, {backgroundColor: 'rgba(0,0,0,.2)', delay: .3, onComplete:function(){
				Array.prototype.forEach.call(_navItems, function(el,i) {
					TweenMax.to(el, .2, {yPercent: 0, autoAlpha: 1, delay: i*.1, ease: Circ.easeOut})
				})
			}})
		}
	}

	function navToggleClass(){
		classList(_mainNavMobile).toggle('active')
	}

}