var classList = require('dom-classlist');
var pubsub = require('pubsub');
var xhr = require('xhr');
var SelectFx = require('periodicjs.component.selectfx')

module.exports = function () { 
	var _rub = document.querySelector('.mediatheque_e8b8c')
	if(!_rub) return;
	var _filterForm = document.querySelector("#filtersForm")
	,	_csSelect
	,	_cs2Select
	, selectCustom = []

	Array.prototype.forEach.call(document.querySelectorAll('select'), function(el,i){
		selectCustom[i] = new SelectFx(el, {
			newTab : false,
			stickyPlaceholder: false,
			onChange: function(val){
				//console.log(val);
				classList(_filterForm).remove('open')

				pubsub.emit('load:more', true)
				//document.querySelector('span.cs-placeholder').style.backgroundImage = 'url(img/'+val+'.png)';
			}
		});

		selectCustom[i].selPlaceholder.addEventListener('click', function(e){
			e.stopPropagation()
			classList(_filterForm).toggle('open')
		})
	})

	_csSelectFormat 	= _filterForm.querySelector('.cs-formats')
	_csSelectTypes 		= _filterForm.querySelector('.cs-types')

	pubsub.on('filterFormats:close', function(){
		classList(_csSelectFormat).remove('cs-active')
		classList(_filterForm).remove('open')
	})
	pubsub.on('filterTypes:close', function(){
		classList(_csSelectTypes).remove('cs-active')
		classList(_filterForm).remove('open')
	})

	//

	if(G.isMobile || G.isTablet){
		
		Array.prototype.forEach.call(_rub.querySelectorAll('a'), function(el){
			el.addEventListener('click', function(e){
				e.stopPropagation()
			})  
		})
	}
}