var classList = require('dom-classlist');
var pubsub = require('pubsub');

module.exports = function () { 
    var _rub = document.querySelector('.b4s3_home')
    if(!_rub) return;

    var _listFiches = _rub.querySelector('.list-fiche')
    ,   _mainHeader = _rub.querySelector('#main-header')
    ,   _fichesNav = _rub.querySelector('.fiches-nav')

    pubsub.on('resize', resizeIOSdevice)

    function resizeIOSdevice(vpW, vpH){
        if(G.isIOS && G.isTablet && vpW > vpH){ // si iPad landscape
            var listeH = vpH - _mainHeader.offsetHeight - _fichesNav.offsetHeight;
            _listFiches.style.height = listeH + 'px';
        }
    }
    resizeIOSdevice(window.innerWidth, window.innerHeight)
    //pubsub.emit('global-resize')
}