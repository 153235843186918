var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');
var serialize = require('form-serialize');

module.exports = function () { 
	var _header = document.querySelector('#main-header')
	,	_alert = document.querySelector('.alert')
	,	_alertWrapper = document.querySelector('.alert-wrapper')
	,	_btAlert = _alert.querySelector('.bt-alert')
	,	_items = _alertWrapper.querySelectorAll('.alert-item')
	,	seq = document.querySelector('.view-item').getAttribute('data-seq')
	,	ItemsSeq = []

	Array.prototype.forEach.call(_items, function(el) {
		ItemsSeq.push(el.getAttribute('data-seq'))
		_link = el.querySelector('a');
		_link.addEventListener('click', function(e){
			e.stopPropagation()
		})
	})

	addHandlers()

	pubsub.emit('cookie:checkContent', ItemsSeq)

	if(seq) pubsub.emit('cookie:checkSequence', seq)
	

	function addHandlers(){
		_btAlert.addEventListener('click', clickBt)
		pubsub.on('alert:close', function(){
			classList(_alertWrapper).remove('active')
		})
		pubsub.on('alert:unHighlight', unHighlight)
	}

	function clickBt(e){
		e.preventDefault()
		e.stopPropagation()
		classList(_alertWrapper).toggle('active')
		pubsub.emit('search:close');
		pubsub.emit('filterFormats:close')
	}

	function unHighlight(seq){
		Array.prototype.forEach.call(_items, function(el) {
			var itemSeq = el.getAttribute('data-seq')
			if(itemSeq == seq){
				classList(el).remove('new')
			}
		})
		checkNotif()
	}

	function checkNotif(){
		var counter = 0;
		Array.prototype.forEach.call(_items, function(el) {
			if(classList(el).contains('new')) counter ++;
		})
		if(counter == 0){ // toutes les alerts ont été vues
			classList(_alert).remove('notif')
		}
	}

}