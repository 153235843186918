var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');
var xhr = require('xhr');
var Tabs = require('tabs');

module.exports = function () { 
	var _rub = document.querySelector('.faq-commentaires_9a665')
	if(!_rub) return;
	var _FAQitems = document.querySelectorAll('.FAQ-list__item')
	
	Tabs();

	Array.prototype.forEach.call(_FAQitems, function(el) {
		var _item = el
		,	nbLikes = _item.getAttribute('data-nblikes')
		,	isLiked = _item.getAttribute('data-liked')
		,	_btLike = _item.querySelector('.bt-like')

		//if(isLiked) classList(_btLike).add('active')

		_btLike.addEventListener('click', clickLike)
	})

	function clickLike(e){
		e.stopPropagation();

		var _btLike = e.target
		,	_nbLikes = _btLike.querySelector('.icon-like')
		,	seq = _btLike.parentNode.getAttribute('data-seq')

		classList(_btLike).toggle('active')

		var action = (classList(_btLike).contains('active')) ? 'like' : 'unlike';
		action += ('&sequence='+seq)
		console.log(action);
		//request
		var urlDatas = window.location.href// + '?todo='+action
		xhr({	
			url: urlDatas,
			headers:{"Content-Type": "application/x-www-form-urlencoded"},
			//json: true,
			method:'POST',
			body: 'todo='+action
			},
			function (err, resp, body) {
				if(err !== null) return;
				_nbLikes.innerText = JSON.parse(body).nb_like;
		});

	}



	var directQuestion = getParameterByName('q');
	if(directQuestion){
		pubsub.emit('accordeon:openItem', directQuestion)
	}

	function getParameterByName(name, url) {
	    if (!url) url = window.location.href;
	    name = name.replace(/[\[\]]/g, "\\$&");
	    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
	        results = regex.exec(url);
	    if (!results) return null;
	    if (!results[2]) return '';
	    return decodeURIComponent(results[2].replace(/\+/g, " "));
	}
}