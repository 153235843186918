var classList = require('dom-classlist');

var pubsub = require('pubsub');
// global object
window.G = window.G || {}
G._html = document.querySelector('html')
G.isMobile = classList(G._html).contains('mobile')
G.isTablet = classList(G._html).contains('tablet')
G.isIOS = classList(G._html).contains('ios')
//
var cookieManager = require('partials/cookie-manager');
var ExpandableMenu = require('partials/expandable-menu');
var mainnav = require('partials/mainnav');
var Contact = require('partials/contact');
var Geoloc = require('partials/geoloc');
var Lightbox = require('partials/lightbox');
var List = require('partials/list-pagination');
var Loader = require('partials/loader');
var MainNavMobile = require('partials/mainnav-mobile');
var MediaGallery = require('partials/media-gallery');
var ScrollWatcher = require('partials/scroll-watcher');
var Search = require('partials/search');
var Alert = require('partials/alert');
var Accordeon = require('partials/accordeon');
//var Share = require('partials/share');
var Swiper = require('partials/swiper');
//
var FichesActions = require('views/fiches-actions');
var Mediatheque = require('views/mediatheque');
var FAQ = require('views/faq');
// var Fiches = require('views/gen_fiche');
//
var _body = document.querySelector('body')

var toDebounce

document.addEventListener("DOMContentLoaded", function(){
   pubsub.emit('document:ready')
});

pubsub.on('body:noscroll', function(){
	classList(_body).add('no-scroll')
})
pubsub.on('body:scroll', function(){
	classList(_body).remove('no-scroll')
})


// Only native resize listener on site
window.addEventListener('resize', resize, false);

// Can be forced from any
pubsub.on('global-resize', resize);
// Global resize
function resize() {
	clearTimeout(toDebounce);
    toDebounce = setTimeout(function(){
    	pubsub.emit('resize', window.innerWidth, window.innerHeight)
    }, 300);
}




function initOnce(){
	Lightbox();
	//ScrollWatcher()
	Search();
	Alert();
	var _expMenus = document.querySelectorAll('.expandable-menu');
	Array.prototype.forEach.call(_expMenus, function(el) {
		var em = ExpandableMenu(el);
	})

	_body.addEventListener('click', function(e){
		/*if(G.isMobile || G.isTablet){
			console.log("default prevented");
			e.preventDefault();
		}*/ 
		pubsub.emit('alert:close')
		pubsub.emit('filterFormats:close')
		pubsub.emit('search:close');
	})

	/*if(G.isMobile || G.isTablet){
		document.addEventListener('touchstart', function(e){
			console.log('target', e.target);
			console.log('currentTarget', e.currentTarget);
			
				e.preventDefault()
		}, true)
	}*/
	
}

function recursiveInit(){
	Loader()
	MainNavMobile()
	Contact();
	Geoloc()
	List();

	Swiper();
	Accordeon()
	Mediatheque()

	FichesActions();
	MediaGallery();
	FAQ();
	// Fiches();
	
}

pubsub.on('document:ready', function(){
	initOnce();
	recursiveInit()
})

// avoiding warnings..
pubsub.on('filterFormats:close', function(){})
pubsub.on('resize', function(){})

