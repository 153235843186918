var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');

module.exports = function () { 
	var _accordeon = document.querySelector('.accordeon')
	if(!_accordeon) return;
	var	_items = _accordeon.querySelectorAll('.accordeon-item')
	,	speed = 0.2
	,	timer

	addHandlers()

	function addHandlers(){
		Array.prototype.forEach.call(_items, function(el) {
			var _item = el
			,	_btOpen = _item.querySelector('.bt-open')

			_btOpen.addEventListener('click', function(){
				if(classList(_item).contains('active')) closeItem(_item) 
				else openItem(_item)
			})
		})

		pubsub.on('accordeon:openItem', function(seq){
			console.log('accordeon:openItem', seq);
			Array.prototype.forEach.call(_items, function(el) {
				if(el.getAttribute('data-seq') == seq){
					console.log("open");
					el.querySelector('.bt-open').click();
				}
			})
		})
	}

	function openItem(_item){
		var _R = _item.querySelector('.FAQ-list__item__R')
		TweenLite.set(_R, {height:"auto"})
    	TweenLite.from(_R, speed, {height:0})
    	classList(_item).add('active');
	}
	function closeItem(_item){
		var _R = _item.querySelector('.FAQ-list__item__R')
    	TweenLite.to(_R, speed, {height:0})
    	classList(_item).remove('active');
	}

	function closeAll(_item){
		Array.prototype.forEach.call(_items, function(el) {
			if(el !== _item) classList(el).remove('active')
		})
	}

}