var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');
var serialize = require('form-serialize');
var xhr = require('xhr');
var SelectFx = require('periodicjs.component.selectfx')

module.exports = function () {
	
	var _contactForm = document.querySelector('#contact-form')
	var _surveyForm = document.querySelector('#survey-form')
	if(!_contactForm) return;

	var _btSubmit = _contactForm.querySelector('.bt')
	,	_inputs
	,	_confirmField = document.querySelector('.confirm')
	,	isLoading = false
	,	to
	,	_selectElements = document.querySelectorAll('select')
	, 	_uploadContactIframe = document.getElementById('upload')
	, 	_uploadSurveyIframe = document.getElementById('upload2')
	;
	/*myFXSelect = new SelectFx(_selectElements,{
	  	newTab : false,
	  	stickyPlaceholder: false,
	  	onChange:function(val){
	  		console.log(val);
	  	}
	  });*/
	
	console.log(_selectElements)
	Array.prototype.forEach.call(document.querySelectorAll('.confirm'), function(el, i){
		el.classList.add('visuallyHidden');
	});

	[].slice.call( _selectElements ).forEach( function(el) {	
		console.log(el);
		var toto = new SelectFx(el, {
			newTab : false,
			stickyPlaceholder: false,
			onChange: function(val){
				console.log(val);
				//document.querySelector('span.cs-placeholder').style.backgroundImage = 'url(img/'+val+'.png)';
			}
		});
	} );

	_inputs = _contactForm.querySelectorAll('input[type="text"], input[type="file"], textarea, .cs-select, .inputFile-container')
	    //expose your component to the window global namespace
	    //window.myFXSelect = myFXSelect;
	
	addHandlers()

	function addHandlers() {
		fileInputManager();
		// _btSubmit.addEventListener('click', clickSubmit);

		_uploadContactIframe.onload = function(){ 
			// alert('contact submited')
			submitForm(_contactForm); 
		}
		_uploadSurveyIframe.onload = function(){ 
			// alert('survey submited')
			submitForm(_surveyForm); 
		}
	}

	function fileInputManager() {
		var inputsFile = document.querySelectorAll('.inputFile-container')
		if( inputsFile.length<1 ) return;
		// for(var i=0;i<=inputsFile.length-1;i++){
		// 	var thisInput = inputsFile[i].querySelector('input[type="file"]')
		// 	  , thisDummyInput = inputsFile[i].querySelector('p')
		// 	  ;
		// 	thisInput.addEventListener('change',function(){
		// 		console.log(thisInput,thisDummyInput)
		// 		thisDummyInput.innerHTML = thisInput.value;
		// 	});
		// }
		Array.prototype.forEach.call(inputsFile, function(el,i){
			el.addEventListener('change',function(){
				var thisInput = el.querySelector('input[type="file"]')
				  ; 
				this.querySelector('p').innerHTML = thisInput.value;
			});
		})
	}
	
	function clickSubmit(e) {
		e.preventDefault();
		// if(classList(_btSubmit).contains('ok')) return;
		// submitForm();
	}

	function submitForm(_form) {
		// if(isLoading) return;
		// isLoading = true;
		// console.log(_form);
		
		_thisContactForm 	= _form;
		_thisConfirmField 	= _thisContactForm.parentNode.querySelector('.confirm');
		_thisBtSubmit 		= _thisContactForm.querySelector('.bt');
		_thisTitle  		= _thisContactForm.parentNode.querySelector('.FAQ-form-container__title');
        _thisGrip  			= _thisContactForm.parentNode.querySelector('.FAQ-form-container__grip');
        _thisDesc  			= _thisContactForm.parentNode.querySelector('.FAQ-form-container__desc');
		_inputs				= _thisContactForm.querySelectorAll('input[type="text"], input[type="file"], textarea, .cs-select, .inputFile-container')
		// console.log(_thisContactForm);
		// console.log(_thisConfirmField);
		// console.log(_thisBtSubmit);


		classList(_thisBtSubmit).remove('ok')
		classList(_thisBtSubmit).remove('ko')
		// classList(_thisConfirmField).remove('active')
		classList(_thisConfirmField).add('visuallyHidden');
		Array.prototype.forEach.call(_inputs, function(input){
			classList(input).remove('error')
		})
		
	    var currentUrl =	document.location.href// _contactForm.getAttribute('action');//
	    ,   reqContent = serialize(_thisContactForm) +'&';
	    ;
	    // reqContent += 'todo=contact';
	    // console.log(reqContent);

        var iframeId = _form.getAttribute("target");
        // console.log(iframeId)
        var body = document.querySelector('#'+iframeId).contentDocument.body.innerHTML;
        // console.log(body)
    	var formValid = true;
        var JSONbody = JSON.parse(body);
        // console.log(JSONbody)
        Array.prototype.forEach.call(JSONbody, function(el, i){
			if(el.level == 'error'){
				if(el.field == 'entity'){
					classList(_thisContactForm.querySelector('.cs-select-entity')).add('error')
				}
				else if(el.field == 'request'){
					classList(_thisContactForm.querySelector('.cs-select-request')).add('error')
				}
				else if(el.field == 'file'){
					classList(_thisContactForm.querySelector('.inputFile-container')).add('error')
				}else {
					classList(_thisContactForm.querySelector('#'+el.field)).add('error')
				}
            	classList(_thisBtSubmit).add('ko');
            	_thisConfirmField.innerHTML = el.msg;
            	formValid = false;
    			// console.log(el.level, el,'ko')
            	clearTimeout(to)
            	to = setTimeout(function(){classList(_thisBtSubmit).remove('ko');}, 1500)
            } else {
            	classList(_thisBtSubmit).add('ok')
            	if(el.field == "btn")_thisBtSubmit.setAttribute("value", el.msg)
            	if(el.field == "msg")_thisConfirmField.innerHTML = el.msg;
                Array.prototype.forEach.call(_inputs, function(input){
                	input.disabled = true;
                	input.readOnly = true;
                })
                classList(_thisContactForm).add('done')
                classList(_thisTitle).add('done')
				classList(_thisGrip).add('done')
				if(_thisDesc) classList(_thisDesc).add('done')
                classList(_thisConfirmField).add('ok')
            }          
        });                        
        
        // classList(_thisConfirmField).add('active')
        classList(_thisConfirmField).remove('visuallyHidden')
        TweenMax.to(_thisBtSubmit, .2, {autoAlpha: 1})

        // classList(_thisConfirmField).add('active')
        classList(_thisConfirmField).remove('visuallyHidden')
    
	}
}
