var pubsub = require('pubsub');
var classList = require('dom-classlist');


module.exports = function () {
	var _filterForm = document.querySelector('#filtersForm')
	if(!_filterForm) return;
	var _filters = _filterForm.querySelectorAll('label')
	,	_fakeRadios = _filterForm.querySelectorAll('.fake-radio') // onglets mediatheque
	,	_btFiltersFormat = _filterForm.querySelector('.bt-filters-formats')
	,	_filtersFormat = document.querySelectorAll('.filter-format')
	,	_filterFormatsList = document.querySelector('.filters-formats__list')

	addHandlers()

	function addHandlers() {
		Array.prototype.forEach.call(_filters, function(el) {
			el.addEventListener('click', clickFilter)
		})

		if(_btFiltersFormat){
			_btFiltersFormat.addEventListener('click', clickBt)
			pubsub.on('filterFormats:close', function(){
				classList(_filterFormatsList).remove('active')
			})
		}
		
	}

	function clickBt(e){
		e.preventDefault()
		e.stopPropagation()
		classList(_filterFormatsList).toggle('active');
	}

	function clickFilter(e) {
		pubsub.emit('load:getStatus', function (isLoading){
			if(isLoading){
				e.preventDefault()
			} else{
				
				var _labelClicked = e.target
				,	_relatedInput = _labelClicked.parentNode.querySelector('input')
				//comportement de radiobutton pour les onglets mediatheque
				if(classList(_relatedInput).contains('fake-radio')){
					Array.prototype.forEach.call(_fakeRadios, function(el) {
						if(el !== _relatedInput) el.checked = false;
					})
				}

				// MOBILE & HOME filters style display based on active or not
				if( G.isMobile && document.querySelector('.fiches-nav') ){
					var _target = e.target.parentNode;
					console.log(e.target,_target)
					// var _filtersLi = document.querySelectorAll('.fiches-nav li');
					if( classList(_target).contains('active') ){
						classList(_target).remove('active');
					}else{
						var _activeFilter = document.querySelector('.fiches-nav li.active');
						if( _activeFilter ) classList( _activeFilter ).remove('active');
						classList(_target).add('active');
					}

				}
				
				// timeout pour serialize form OK
				setTimeout(function(){

					pubsub.emit('load:more', true);


					if(classList(_relatedInput).contains('filter-format')){
						//console.log('format clicked', _relatedInput.checked);
						if(_relatedInput.checked){
							console.log('format checked');
							classList(_btFiltersFormat).add('active')
						} else {
							console.log('format unchecked');
							var isActive = false;
							Array.prototype.forEach.call(_filtersFormat, function(el) {
								if(el.checked){
									isActive = true;
									return false;
								}
							})
							//console.log(isActive);
							if(isActive) classList(_btFiltersFormat).add('active')
								else classList(_btFiltersFormat).remove('active')
						}
					}


					pubsub.emit('filterFormats:close')

				}, 200)
				
			}
		});
		
	} 
}
