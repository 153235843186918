var pubsub = require('pubsub');
var gsap = require('gsap');
var Swiper = require('swiper');
var classList = require('dom-classlist');
var getQrParam = require('getQrParam');

module.exports = function () {
	var _swipers = document.querySelectorAll('.swiper-container')
	,	_swiperFichesElt = document.querySelector('.swiper-container.list-fiche')
	,	_swiperFiches
	,	swiperDirection
	,	winW = window.innerWidth
	,	breakPoint = 768
	,	_filters = document.querySelectorAll('.fiches-nav li')
	,	_swiperSlides = document.querySelectorAll('.list-fiche__item')
	,	categories = {}
	,	to
	;

	pubsub.on('swiper:listFichesInit', function(){
		if(_swiperFichesElt){
			swiperFichesActions()
		}
		/*Array.prototype.forEach.call(_swipers, function(el){
			if(classList(el).contains('list-fiche')) swiperFichesActions();
		})*/
	})

	pubsub.on('resize', resizeSwiper);

	function resizeSwiper(w, h){
		winW = w;
		if(_swiperFiches){
			// _swiperFiches.destroy(true, true)
			// clearTimeout(to);
			// to = setTimeout(swiperFichesActions, 300);
			_swiperFiches.update();
		}
	}


	function swiperFichesActions(){

		if(winW > breakPoint){
			swiperDirection = 'horizontal';
		} else {
			swiperDirection = 'vertical';
		}


		initswiperFichesProgress();

		_swiperFichesElt.addEventListener('touchmove', function(e){
			e.stopPropagation()
		})

		_swiperFiches = new Swiper (_swiperFichesElt, {
			// Optional parameters
			direction: swiperDirection,
			loop: false,
			threshold: 50,
			freeMode: true,
			freeModeSticky: (swiperDirection == 'horizontal')?true:false,
			spaceBetween: 0,
			slidesPerView: 'auto',
			watchSlidesProgress: true,
			watchSlidesVisibility: true,
			mousewheelControl: true,

			// Navigation arrows
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev',

			observeParents: true,
			observer: true,

			// And if we need scrollbar
			//scrollbar: '.swiper-scrollbar',
			//onSlideChangeStart: updateSwiperProgress,
			onProgress: updateSwiperProgress ,
			onResize: updateSwiperProgress,
			onInit: function(swiper){
		  //   	// mobile / tablet "anchor" swipeTo management
				// // if( G.isTablet || G.isMobile ){
				// if( G.isMobile ){
				// 	Array.prototype.forEach.call(_filters, function(el) {
				// 		el.addEventListener('click', function(){
				// 			var thisSv = el.getAttribute('data-sv');
				// 			var target = document.querySelector('.liste-gen_item-sv-'+thisSv);
				// 			var index = target.getAttribute('data-index');
				// 			// console.log(el,thisSv,target,index)
				// 			swiper.slideTo(index, 500, function(){});
				// 		})
				// 	})
				// }

				// get QR if exists then swipeTo targetted seq/elt
				var _qrData = getQrParam('seq');
				if( _qrData != '' && _qrData != null ){
					var target = null;
					Array.prototype.forEach.call(_swiperSlides, function(el) {
						// console.log(el.getAttribute('href'),_qrData,el.getAttribute('href').indexOf(_qrData))
						if( el.getAttribute('href').indexOf(_qrData) != -1  ) target = el;
					});
					// console.log(target)
					if(target){
						var index = target.getAttribute('data-index');
						swiper.slideTo(index, 500, function(){});
					}
				}

				updateSwiperProgress(swiper);
				if(_swiperFiches) _swiperFiches.update();
			},
		 //    onFirstInit: function(swiper){
			// 	if( G.isMobile ){
			// 		Array.prototype.forEach.call(_filters, function(el) {
			// 			el.addEventListener('click', function(){
			// 				console.log(el)
			// 				classList(el).toggle('active');
			// 			})
			// 		})
			// 	}
			// },
			onTransitionEnd: updateSwiperProgress
			/*breakpoints: {
				// when window width is <= 320px
				1100: {
					direction: 'vertical',
				}
			}*/
		})
	}

	function initswiperFichesProgress(){
			Array.prototype.forEach.call(_filters, function(el){
				var sv = el.getAttribute('data-sv')
				categories[sv] = []
			})

			_swiperSlides = document.querySelectorAll('.list-fiche__item')
			Array.prototype.forEach.call(_swiperSlides, function(el){
				var sv = el.getAttribute('data-sv')
				categories[sv].push(el)
				el.addEventListener('click', function(e){
					e.stopPropagation()
				})
			})
		}

	// Array.prototype.forEach.call(_filters, function(el){
	// 		el.addEventListener('click',initswiperFichesProgress)
	// 	})

	function updateSwiperProgress(swiper){
		// _swiperSlides = document.querySelectorAll('.list-fiche__item')

		if(swiperDirection == 'horizontal'){
			// create an empty object to compare to the original
			var clonedCategory = {}
			,	activeIndex = swiper.activeIndex
			for(var sv in categories){
				clonedCategory[sv] = [];
			}
			// stock viewed items
			Array.prototype.forEach.call(_swiperSlides, function(el, i){
				if(i <= activeIndex || classList(el).contains('swiper-slide-visible')){
					var sv = el.getAttribute('data-sv')
					clonedCategory[sv].push(el)
				}
			})
			// compare viewed items / original items to get precentage progress
			for(var sv in categories){
				var _filter = getActiveFilter(sv)
				,	_background = _filter.querySelector('.progress-bg')
				,	percent = getPercentCategory(clonedCategory[sv].length, categories[sv].length)
				_background.style.width = percent + '%';
			}
		} else {
			// var activeIndex = swiper.activeIndex || 0
			// ,	_activeSlide = _swiperSlides[activeIndex]
			// ,	sv = _activeSlide.getAttribute('data-sv')
			// ,	_filter = getActiveFilter(sv)
			// ;
			// console.log();
			// Array.prototype.forEach.call(_filters, function(el){
			// 	classList(el).remove('active')
			// })
			// if( _swiperFiches && _swiperFiches.isEnd ) {
			// 	classList(_filters[_filters.length-1]).add('active');
			// }else{
			// 	classList(_filter).add('active');
			// }
		}
	}


	function getPercentCategory(nbItems, nbTotal){
		return nbTotal ? nbItems * 100 / nbTotal : 0;
	}


	function getActiveFilter(activeSv){
		var _activeFilter
		for(var i = 0; i< _filters.length; i++){
			var _el = _filters[i]
			,	sv = _el.getAttribute('data-sv')
			if(sv == activeSv){
				_activeFilter = _el;
				break;
			}
		}
		return _activeFilter;
	}

	if(document.querySelector('.b4s3_home')) pubsub.emit('swiper:listFichesInit');

}
