var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');

/*var breakpointS = 768;

function checkMobileNav(){
	if(G.isMobile || G.isTablet | window.innerWidth <= breakpointS){
		var _mobileNav = document.querySelector('.main-nav-mobile')
		,	_btBurger = _mobileNav.querySelector('.bt-burger')
	}
}

checkMobileNav();

pubsub.on('resize', checkMobileNav)*/