var Cookies = require('js-cookie');
var pubsub = require('pubsub');

var cookieName = 'NatAss'
,	cookieContent = {}
,	onBoard = Cookies.get(cookieName);

if(onBoard){
	// onBoard est le contenu String du cookie que je convertis en Object
	cookieContent = JSON.parse(onBoard);
}

pubsub.on('cookie:checkContent', checkContent)
pubsub.on('cookie:checkSequence', checkSequence)

function checkContent(seqArray){
	if(getLength(cookieContent) <= 0){ // le cookie n'existe pas, je créé son contenu
		for(var i = 0; i<seqArray.length; i++){
			var seq = seqArray[i];
			cookieContent[seq] = false;
		}
	} else { // le cookie existe, je compare son contenu avec les notifs remontées par le BO
		for(var z in cookieContent){
			var stillExists = false;
			for(var i = 0; i<seqArray.length; i++){
				var seq = seqArray[i];
				if(z == seq){
					stillExists = true;
					break;
				}
			}
			if(stillExists){ // la notif présente dans l'objet fait toujours partie des notifs en cours
				if(cookieContent[z]){ //si la notif a été vue j'update le panneau d'alertes
					pubsub.emit('alert:unHighlight', z);
				}
			} else { // la notif n'existe plus, je la supprime
				delete cookieContent[z];
			}
		}
	}
	// je créé ou mets à jour le cookie avec le contenu obtenu
	setCookie()
}



function checkSequence(seq){
	if(typeof cookieContent[seq] !== 'undefined' && !cookieContent[seq]){ //si la seq fait partie des notifs et qu'elle n'a pas été consultée
		cookieContent[seq] = true;
		setCookie();
		pubsub.emit('alert:unHighlight', seq);
	}
}

function setCookie(){ // création / mise à jour du cookie
	Cookies.set(cookieName, cookieContent, { expires: 365 });
}

function getLength(obj){
	var count = 0;
	for (i in obj) {
	    if (obj.hasOwnProperty(i)) {
	        count++;
	    }
	}
	return count;
}

//}