var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');
var serialize = require('form-serialize');

module.exports = function () { 
	var _tabs = document.querySelector('.tabs')
	if(!_tabs) return;
	var _tabItem = _tabs.querySelectorAll('.tabs__item')
	,	_tabContents = document.querySelectorAll('.tab_content')
	addHandlers()

	function addHandlers(){
		Array.prototype.forEach.call(_tabItem, function(el, i) {
			el.index = i;
			el.addEventListener('click', function(e){
				e.preventDefault()
				activeTab(this.index);
				displayTabContent(this.index);
			})
		})
	}

	function activeTab(index){
		Array.prototype.forEach.call(_tabItem, function(el, i) {
			classList(el).remove('active')
		})
		classList(_tabItem[index]).add('active')
	}

	function displayTabContent(index){
		Array.prototype.forEach.call(_tabContents, function(el, i) {
			classList(el).remove('active')
		})
		classList(_tabContents[index]).add('active')
	}


}