var classList = require('dom-classlist');
var gsap = require('gsap');
var pubsub = require('pubsub');
var serialize = require('form-serialize');
var xhr = require('xhr');

module.exports = function () { 
	var _header = document.querySelector('#main-header')
	,	_search = _header.querySelector('.search')
	,	_btSearch = _search.querySelector('.bt-search')
	,	_btClose = _search.querySelector('.bt-close')
	,	_body = document.querySelector('body')
	,	_searchForm = document.querySelector('.search-form')
	,	_input = _searchForm.querySelector('input')
	,	_searchResultsWrapper = document.querySelector('.search__results')
	,	_suggestionsWrapper = _search.querySelector('.suggestions')
	,	_suggestItems
	,	isOpened = false
	,	currentUrl = _searchForm.getAttribute('action')
	,	toAC
	,	indexSugest = -1
	,	enteredValue

	addHandlers()

	function addHandlers(){
		_btSearch.addEventListener('click', clickSearch)
		_btClose.addEventListener('click', function(){
			pubsub.emit('search:close');
		})
		pubsub.on('search:closeSuggest', function(){
			_suggestionsWrapper.innerHTML = '';
			indexSugest = -1
			classList(_suggestionsWrapper).remove('active')
			classList(_search).remove('overflow')
		})
		pubsub.on('search:closeResults', function(){
			classList(_searchResultsWrapper).remove('active')
			classList(_body).remove('search-opened')
		})
		pubsub.on('search:close', function(){
			isOpened = false;
			pubsub.emit('search:closeSuggest');
			pubsub.emit('search:closeResults');
			classList(_search).remove('active')
			_input.value = '';
		})

        _input.addEventListener('keyup', keyup, false)

		_searchResultsWrapper.addEventListener('click', function(e){
			e.stopPropagation();
		})
		_input.addEventListener('click', clickInput)
		_searchForm.addEventListener('submit', submitForm)

	}

	function clickSearch(e){
		e.preventDefault()
		e.stopPropagation()
		pubsub.emit('alert:close')
		pubsub.emit('filterFormats:close')
		if(!isOpened){
			classList(_search).toggle('active')
			isOpened = true;
			_input.focus()
		} else {
			submitForm()
		}
		
	}

	function clickInput(e){
		e.stopPropagation();
		pubsub.emit('search:closeSuggest');
		pubsub.emit('search:closeResults');
	}

	function submitForm(e){
		if(e) e.preventDefault()
		//var currentUrl = _searchForm.getAttribute('action')+'?showJSON'
	    var  reqContent = _searchForm ? serialize(_searchForm) : '';
	    ;
	    reqContent += '&type=search-results'; // define php file to call == com.search-results.php 
	    reqContent += '&todo=search';

	    pubsub.emit('search:closeSuggest');
	    pubsub.emit('search:closeResults');
		//classList(_loader).add('active');
		xhr({
		        url: currentUrl
		    ,   headers:{"Content-Type": "application/x-www-form-urlencoded"}
		    ,   method:'POST'
		    ,   body: reqContent
		    }
		    ,   function (err, resp, body) {
		    	
		            if(err == null){
		            	isLoading = false;
		                var JSONbody = JSON.parse(body)[0];
		                // count results and compare to pagination
		                var dummy = document.createElement('div');
		                dummy.innerHTML = JSONbody.msg;		                		                
		           
		                //classList(_loader).remove('active');
		                _searchResultsWrapper.innerHTML = JSONbody.msg;
		               classList(_searchResultsWrapper).add('active')
		               classList(_body).add('search-opened')
		               pubsub.emit('search:closeSuggest');
		            } else {
		                //_loaderMoreArticles.classList.remove('active')
		                console.log(err);
		            }
		        }
		)
	}

	function keyup(e){
		var keyCode = e.keyCode
		//console.log(keyCode);
		if(keyCode == 27){ //escape
			pubsub.emit('search:close')
		} else if(keyCode == 13){ //enter
			pubsub.emit('search:closeSuggest')
		} else if(keyCode == 40){ // arrow down
			manageArrowsSuggest(1)
		} else if(keyCode == 38){ //arrow up
			manageArrowsSuggest(-1)
		} else {
			if(_input.value.length >= 1){
				enteredValue = _input.value;
				clearTimeout(toAC)
				toAC = setTimeout(getAutoComplete, 300)
			} else {
				pubsub.emit('search:closeSuggest');
			}
		}
	}

	function getAutoComplete(){
		var reqContent = serialize(_searchForm)
	    reqContent += '&todo=autocompletion';
	    removeSuggestionsHandlers()
		//classList(_loader).add('active');
		xhr({
		        url: currentUrl
		    ,   headers:{"Content-Type": "application/x-www-form-urlencoded"}
		    ,   method:'POST'
		    ,   body: reqContent
		    }
		    ,   function (err, resp, body) {
		            if(err == null){
		            	isLoading = false;
		            	_suggestionsWrapper.innerHTML = '';
		            	if(typeof body !== 'undefined' && body != '[]'){
		            		var JSONbody = JSON.parse(body)
		            		,	value = _input.value
		            		,	valueLength = value.length

		            		for(var i=0; i<JSONbody.length; i++){
		            			var indexStart = JSONbody[i].toLowerCase().indexOf(value.toLowerCase())
		            			,	indexEnd = indexStart + valueLength
		            			,	matching = JSONbody[i].slice(indexStart, indexEnd)
								,	sug = JSONbody[i].replace(matching, "<span>"+matching+"</span>")
		            			,	HTML = '<div class="suggestions__item">'+sug+'</div>'
		            			//HTML.replace(reqContent, "<span>"+reqContent+"</span>");
		            			_suggestionsWrapper.innerHTML += HTML
		            		}
		            		_suggestItems = _suggestionsWrapper.querySelectorAll('.suggestions__item')
		            		addSuggestionsHandlers()
		            		classList(_suggestionsWrapper).add('active')
		            		classList(_search).add('overflow')
		            	} else {
		            		pubsub.emit('search:closeSuggest');
		            	}
		                
		               
		           
		                //classList(_loader).remove('active');
		               
		            } else {
		                //_loaderMoreArticles.classList.remove('active')
		                console.log(err);
		            }
		        }
		)
	}

	function addSuggestionsHandlers(){
		if(!_suggestItems) return;
		Array.prototype.forEach.call(_suggestItems, function(el, i){
			el.addEventListener('click', clickSuggest);
		})
	}
	function removeSuggestionsHandlers(){
		if(!_suggestItems) return;
		Array.prototype.forEach.call(_suggestItems, function(el, i){
			el.removeEventListener('click', clickSuggest);
		})
	}

	function clickSuggest(e){
		e.stopPropagation()
		var txt = e.target.innerText;
		_input.value = txt;
		submitForm();
	}

	function manageArrowsSuggest(direction){
		if(_input.value/length <= 0) return;
		var newIndex = indexSugest + direction

		if(newIndex < 0){
			indexSugest = -1;
			_input.value = enteredValue;
		} else if(newIndex > _suggestItems.length -1){
			indexSugest = 0
		} else {
			indexSugest = newIndex;
		}
		Array.prototype.forEach.call(_suggestItems, function(el, i){
			classList(el).remove('active')
		})

		if(indexSugest <= -1) return;
		var _suggestItem = _suggestItems[indexSugest]
		classList(_suggestItem).add('active')
		_input.value = _suggestItem.innerText
	}

}